<template>
<card-template
  :item="item"
  :selected="selected"
  :compact="compact"
>
  <template #content>
    <div class="property property--description"
      v-html="item.note_text"
    ></div>
  </template>
  <template #content-compact>
    <div class="property property--subject">
        {{ item.note_text }}
      </div>
      <div class="property property--date">
        {{ formatDateTime(item?.event_timekey) }}
      </div>
    </template>
</card-template>
</template>

<script>
import cardTemplate from "./card-base-template.vue";
import dateTimeFormatMixin from "@shared/mixins/dateTimeFormat";

export default {
  name: 'card-note',
  components: {
    cardTemplate,
  },
  mixins: [dateTimeFormatMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
    },
    compact: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
</style>